<template>
  <div>
    <!-- <standbyDialog :localLoadingDialog="localLoadingDialog"></standbyDialog> -->
    <!-- <formHeaderScript></formHeaderScript> -->
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col sm="8" md="4">
          <messagesContainer :result="result"></messagesContainer>
          <!-- here  {{result.infoFormMessage}}
          <infoFormMessage
            v-if="typeof result.infoFormMessage!='undefined'"
            :message="result.infoFormMessage"
            :result="result"
          ></infoFormMessage>-->

          <div>
            <v-card>
              <windowsFormHeader :result="result"></windowsFormHeader>
              <div
                class="ml-4 mr-4 mt-4"
                v-if="typeof result.formMessage != 'undefined'"
              >
                {{ result.formMessage }}
              </div>
              <v-card-text>
                <v-form
                  v-bind="formHeader"
                  v-model="isValid"
                  ref="form"
                  @submit.prevent
                >
                  <formTable
                    :result="result"
                    :valueLine="result.valueLine"
                    v-on="$listeners"
                    @revalidate="revalidate"
                    @changeIsValid="changeIsValid"
                    :isValid="isValid"
                  ></formTable>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <fieldsButtons
                  :result="result"
                  :isValid="isValid"
                  @submitted="submitForm(...arguments)"
                ></fieldsButtons>
              </v-card-actions>
            </v-card>

            <formFooter :result="result"></formFooter>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { getdefaultFormHeader } from "@/js/helper.js";
import formTable from "@/commonComponents/formTable.vue";
import formFooter from "@/commonComponents/formFooter.vue";
import fieldsButtons from "@/commonComponents/fieldsButtons.vue";
import windowsFormHeader from "@/commonComponents/windowsFormHeader.vue";
import messagesContainer from "@/commonComponents/messagesContainer.vue";
//import standbyDialog from "@/commonComponents/standbyDialog.vue";
// import infoFormMessage from "@/commonComponents/infoFormMessage.vue";
export default {
  data: function () {
    return {
      isValid: false,
      formHeader: getdefaultFormHeader(this.result),
      formRef: "form",
      localLoadingDialog: false,
    };
  },
  props: ["result"],
  components: {
    formTable,
    formFooter,
    fieldsButtons,
    windowsFormHeader,
    // infoFormMessage,
    messagesContainer,
   // standbyDialog,
  },
  created() {
    this.$nextTick(function () {
      this.$refs[this.formRef].validate();
    });

    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "dataSubmitted") {
        this.localLoadingDialog = false;
      }
    });
  },

  mounted() {
    let self = this;
    window.addEventListener("keyup", self.keyUpHandeler);
  },
  beforeDestroy() {
    let self = this;
    window.removeEventListener("keyup", self.keyUpHandeler);
     this.unsubscribe();
  },
  methods: {
    revalidate() {
      //console.log("revalidating");
      this.$set(this.result, "valid", this.$refs[this.formRef].validate());
      this.$store.commit("dialogResultChanged", this.result);
    },
    changeIsValid(value) {
      if (this.$refs[this.formRef].validate()) {
        //  console.log("changeIsValid", value);
        this.isValid = value;
      }
    },
    keyUpHandeler(event) {
      if (event.keyCode === 13) {
        this.submitWithoutName();
      }
    },
    submitWithoutName() {
      console.log("submted");
      let firstFunction = Object.keys(this.result.function)[0];
      let functionCalled = this.result.function[firstFunction].function;
      this.submitForm(functionCalled);
    },
    submitForm(functionName) {
      if (typeof this.$refs.form != "undefined" && this.$refs.form.validate()) {
        this.localLoadingDialog = true;
        this.ajaxSubmit(
          functionName,
          this.$refs.form.$el,
          this.result.tableUrl,
          this.result
        );
      }
    },
  },
};
</script>