<template>
  <dynamicComponent :type="this.result.outsideFormTemplate"></dynamicComponent>
</template>
<script>
import dynamicComponent from "./dynamicComponent.vue";
export default {
  components: { dynamicComponent },
  props: {
    result: {
      type: Object,
      default: function() {
        return { outsideFormTemplate: null };
      }
    }
  }
};
</script>